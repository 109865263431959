import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Search from "../components/search";

const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title="ไม่พบข้อมูล" />
      <div className="container mx-auto px-4 max-w-screen-sm">
        <section className="mt-4">
          <Search />
        </section>
        <section className="my-8 text-center text-gray-700">
          <h1 className="text-xl">ไม่พบข้อมูล</h1>
        </section>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
